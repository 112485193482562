export enum Topic {
  connected = "connected",
  getNotification = "get_notification",
  setNotification = "set_notification",
  setPreference = "set_preference",
  markReadNotification = "mark_read_notification",
  markNotificationResolved = "mark_notification_resolved",
  getAssignedUnits = "get_assigned_units",
  setAssignedUnits = "set_assigned_units",
  getUnitDetails = "get_unit_details",
  setUnitDetails = "set_unit_details",
  getRalert = "get_r_alert",
  setRalert = "set_r_alert",
  cancelRalert = "cancel_r_alert",
  completeRalert = "complete_r_alert",
  getAlertToaster = "get_alert_toaster",
  setAlertToaster = "set_alert_toaster",
  closeAlertToaster = "close_alert_toaster",
  setRalertCST = "set_r_alert_cst",
  getRalertCST = "get_r_alert_cst",
  completeRalertCST = "complete_r_alert_cst",
  updateRAlertCST = "update_r_alert_cst",
}
